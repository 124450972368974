import img1 from "../img/testimonial/slider-1.png";
import img2 from "../img/testimonial/slider-2.png";
import img3 from "../img/testimonial/slider-3.png";
import ashik from "../img/testimonial/ashik.png";
import farhad from "../img/testimonial/farhad.png";

export const TestimonialList = [
  {
    id: 1,
    image: img1,
    name: "Siam Hossain",
    rating: 4,
    description:
      `I had the pleasure of working with Habibul Hasan on building my startup's tech product using a modern 
      technology stack.
       he was not only incredibly intelligent but also a fast learner, 
       adapting quickly to new challenges and delivering high-quality work. His expertise in deploying and managing our 
       cloud infrastructure was invaluable, ensuring smooth operations and scalability. 
       I highly recommend [Developer's Name] for any project that requires skill, speed, and a proactive mindset`,
  },
  {
    id: 2,
    image: ashik,
    name: "Ashiq Uz Zoha",
    rating: 5,
    description:
      `I collaborated with Habibul on a startup product and was impressed by his approach to
      product design and technical implementation. He utilized modern tools and frameworks, delivering
       efficient and scalable solutions that suited the fast-paced startup environment.

He’s a true team player and passionate startup enthusiast, always contributing new ideas and 
adapting to the project’s needs.His enthusiasm and technical expertise had a major impact on the product’s success.I highly recommend him for any startup or innovative venture.
      `,
  },
  {
    id: 3,
    image: farhad,
    name: "Farhad Alam Bhuiyan",
    rating: 2,
    description:
      `
      I had the pleasure of working with Habibul Hasan at shohoz.com, where his quick learning and adaptability in tech stood out. He swiftly grasped new concepts and expertly integrated modern web technologies into our projects, helping build a tech startup product in record time. His deep knowledge of cutting-edge tools and proactive problem-solving led to high-quality solutions.

In addition to his technical skills, Habibul Hasan is an excellent communicator and team player, always willing to share insights and collaborate. I highly recommend him for any opportunity requiring innovation, dedication, and excellence.`,
  },
];
